<template>
  <ul class="dropdown__list">
    <li v-for="option in options" :key="option.type" @click="$emit('selectType', option.type)">
      <p>{{ option.title }}</p>
    </li>
  </ul>
</template>

<script>
import vClickOutside from "click-outside-vue3";

export default {
  name: "EmailMenuCheckbox",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    options: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>

</style>
