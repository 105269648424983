<template>
  <ul class="dropdown__list">
    <li
      v-for="option in options"
      :key="option.folder"
      @click="$emit('pickOption', option.folder)"
    >
      <p>{{ option.title }}</p>
    </li>
  </ul>
</template>

<script>
import vClickOutside from "click-outside-vue3";

export default {
  name: "EmailMenuMoveTo",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
