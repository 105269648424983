<template>
  <div class="email-action">
    <div class="email-action__group" v-if="view.checkbox || view.refresh">
      <div class="email-action__btn combined" v-if="view.checkbox">
        <template v-if="!checked || !isCheckIndeterminate">
          <div style="display: flex">
            <FveCheckbox
              :field="{
                name: 'email_all',
                model: true,
              }"
              @update:modelValue="$emit('checkAll', $event)"
              :modelValue="!!checked"
            />
          </div>
        </template>

        <template v-else>
          <div style="display: flex" @click="$emit('checkAll', false)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 6h-4c-1.47 0-2.373.004-3.025.092-.574.077-.67.187-.681.2a.025.025 0 01-.002.002c-.013.011-.123.107-.2.68C6.004 7.628 6 8.53 6 10v4c0 1.47.004 2.373.092 3.025.077.574.187.67.2.681v.001l.002.001c.011.013.107.123.68.2C7.628 17.996 8.53 18 10 18h4c1.47 0 2.373-.004 3.025-.092.574-.077.67-.187.681-.2h.001l.001-.002c.013-.011.123-.107.2-.68.088-.653.092-1.555.092-3.026v-4c0-1.47-.004-2.373-.092-3.025-.077-.574-.187-.67-.2-.681v-.001l-.002-.001c-.011-.013-.107-.123-.68-.2C16.372 6.004 15.47 6 14 6zM4.879 4.879C4 5.757 4 7.172 4 10v4c0 2.828 0 4.243.879 5.121C5.757 20 7.172 20 10 20h4c2.828 0 4.243 0 5.121-.879C20 18.243 20 16.828 20 14v-4c0-2.828 0-4.243-.879-5.121C18.243 4 16.828 4 14 4h-4c-2.828 0-4.243 0-5.121.879z" fill="#566BD6"/><path d="M8 11c0-1.414 0-2.121.44-2.56C8.878 8 9.585 8 11 8h2c1.414 0 2.121 0 2.56.44.44.439.44 1.146.44 2.56v2c0 1.414 0 2.121-.44 2.56-.439.44-1.146.44-2.56.44h-2c-1.414 0-2.121 0-2.56-.44C8 15.122 8 14.415 8 13v-2z" fill="#566BD6"/></svg>
          </div>
        </template>

        <PopperDropdown placement="bottom-start" :skidding="-22">
          <div class="email-action__dropdown">
            <!-- prettier-ignore -->
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'email-action__caret'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.792 16.97a1 1 0 01-1.584 0L6.31 10.61A1 1 0 017.104 9h9.792a1 1 0 01.793 1.61l-4.897 6.36z"/></svg>
          </div>
          <template #popper="{ hide }">
            <EmailMenuCheckbox
              @selectType="$emit('selectByType', $event), hide()"
              :options="selectTypeList"
            />
          </template>
        </PopperDropdown>
      </div>

      <div
        @click="$emit('refresh')"
        v-if="view.refresh"
        class="email-action__btn"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.91 10.244l6.913-.754L15.325 3l-1.367 2.243A8 8 0 1020 13a1 1 0 10-2 0 6 6 0 11-5.15-5.94l-1.94 3.184z"/></svg>
      </div>
    </div>

    <div
      class="email-action__group"
      v-if="view.delete || view.markRead || view.markUnread || view.spam"
    >
      <div
        v-if="view.delete"
        v-tooltip.right-end="'Delete'"
        @click="$emit('delete')"
        :class="{ disabled: !checked && $route.name !== $routeName.EMAIL_READ }"
        class="email-action__btn delete"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18.906 6.757c.324 0 .594.274.594.623v.323c0 .341-.27.624-.594.624H5.095c-.325 0-.595-.283-.595-.624V7.38c0-.349.27-.623.595-.623h2.43c.493 0 .923-.358 1.034-.863l.127-.58C8.884 4.524 9.535 4 10.279 4h3.442c.736 0 1.394.524 1.585 1.272l.136.62c.11.507.54.865 1.034.865h2.43z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M6 10.61a.602.602 0 01.15-.43.559.559 0 01.41-.18h10.885c.156 0 .298.067.41.18.105.121.158.271.142.43 0 .053-.394 4.996-.633 7.182-.15 1.341-1.03 2.156-2.352 2.178-1.015.022-2.008.03-2.986.03-1.038 0-2.053-.008-3.038-.03-1.277-.03-2.158-.859-2.3-2.178-.197-1.76-.515-5.284-.637-6.636L6 10.61zm9.777 2.622c.038-.441.073-.86.103-1.232H8.135l.112 1.214c.14 1.517.308 3.28.429 4.355v.009c.028.257.107.33.11.333a.136.136 0 00.047.027c.03.012.094.03.202.033.964.021 1.963.029 2.991.029.967 0 1.945-.008 2.943-.03h.01a.742.742 0 00.241-.036c.034-.013.046-.023.048-.026.003-.003.02-.017.04-.061a.89.89 0 00.068-.275c.116-1.066.273-2.824.401-4.34z"/></svg>
      </div>

      <div
        v-if="view.markRead"
        v-tooltip.right-end="'Mark as read'"
        @click="$emit('setRead')"
        :class="{ disabled: !checked && $route.name !== $routeName.EMAIL_READ }"
        class="email-action__btn"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.586 6.586C4 7.172 4 8.114 4 10v4c0 1.886 0 2.828.586 3.414C5.172 18 6.114 18 8 18h8c1.886 0 2.828 0 3.414-.586C20 16.828 20 15.886 20 14v-4c0-1.886 0-2.828-.586-3.414C18.828 6 17.886 6 16 6H8c-1.886 0-2.828 0-3.414.586zm7.062 7.066a.5.5 0 00.704 0l4.846-4.797A.5.5 0 0016.846 8H7.154a.5.5 0 00-.352.855l4.846 4.797z"/></svg>
      </div>

      <div
        v-if="view.markUnread"
        v-tooltip.right-end="'Mark as unread'"
        @click="$emit('setUnread')"
        class="email-action__btn"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.586 6.586C4 7.172 4 8.114 4 10v4c0 1.886 0 2.828.586 3.414C5.172 18 6.114 18 8 18h8c1.886 0 2.828 0 3.414-.586C20 16.828 20 15.886 20 14v-4c0-1.886 0-2.828-.586-3.414C18.828 6 17.886 6 16 6H8c-1.886 0-2.828 0-3.414.586zm7.062 7.066a.5.5 0 00.704 0l4.846-4.797A.5.5 0 0016.846 8H7.154a.5.5 0 00-.352.855l4.846 4.797z"/></svg>
      </div>

      <div
        v-if="view.spam"
        v-tooltip.right-end="'Spam'"
        @click="$emit('setSpam')"
        :class="{ disabled: !checked && $route.name !== $routeName.EMAIL_READ }"
        class="email-action__btn"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 4l2.203 4.028L21 12l-2.297 3.972L16.5 20l-4.5-.056L7.5 20l-2.203-4.028L3 12l2.297-3.972L7.5 4l4.5.056L16.5 4zm.472 5.029L18.69 12l-1.718 2.971-.012.02-.012.021-1.626 2.973-3.297-.04h-.05l-3.297.04-1.626-2.973-.012-.02-.012-.021L5.31 12l1.718-2.971.012-.02.012-.021 1.626-2.973 3.297.04h.05l3.297-.04 1.626 2.973.012.02.012.021zM12.819 16v-5.393h-1.638V16h1.638zm-1.543-6.279c.184.193.425.289.724.289s.54-.096.724-.289c.184-.199.276-.447.276-.746a.93.93 0 00-.276-.696C12.54 8.093 12.3 8 12 8s-.54.096-.724.289a.996.996 0 00-.276.716c0 .285.092.524.276.716z"/></svg>
      </div>
    </div>

    <PopperDropdown placement="bottom-start" :distance="-4">
      <div
        v-tooltip.right-end="'Move to'"
        :class="{ disabled: !checked && $route.name !== $routeName.EMAIL_READ }"
        class="email-action__btn"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 8a3 3 0 013-3h4.172a2 2 0 011.414.586l.828.828A2 2 0 0014.828 7H17a3 3 0 013 3v7a3 3 0 01-3 3H7a3 3 0 01-3-3V8zm12.354 4.646l-3.182-3.182a.5.5 0 10-.708.708l2.329 2.328H8a.5.5 0 000 1h6.793l-2.329 2.328a.5.5 0 10.708.707l3.182-3.181a.5.5 0 000-.708z"/></svg>
      </div>
      <template
        #popper="{ hide }"
        v-if="checked || $route.name === $routeName.EMAIL_READ"
      >
        <EmailMenuMoveTo
          @pickOption="this.$emit('moveTo', $event), hide()"
          :options="moveToList"
        />
      </template>
    </PopperDropdown>
    <!-- TODO: Вернуть когда появится функционал на бэке -->
    <!-- <div
      v-tooltip.right-end="'Star'"
      @click="$emit('markStarred')"
      class="email-action__btn"
      :class="{ disabled: !checked && $route.name !== $routeName.EMAIL_READ }"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stroke-star'" :class="{ marked: isMarked }" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 6.286l-1.058 2.006A3 3 0 018.8 9.848l-2.234.386 1.58 1.626a3 3 0 01.818 2.518l-.323 2.245 2.035-1a3 3 0 012.648 0l2.035 1-.323-2.245a3 3 0 01.818-2.518l1.58-1.626-2.234-.386a3 3 0 01-2.142-1.556L12 6.286zm.884-2.61a1 1 0 00-1.768 0L9.173 7.358a1 1 0 01-.714.519l-4.102.71a1 1 0 00-.547 1.682l2.902 2.985a1 1 0 01.273.84l-.593 4.12a1 1 0 001.431 1.04l3.736-1.837a1 1 0 01.882 0l3.736 1.837a1 1 0 001.431-1.04l-.593-4.12a1 1 0 01.273-.84l2.902-2.985a1 1 0 00-.547-1.682l-4.102-.71a1 1 0 01-.714-.519l-1.943-3.682z"/><path d="M11.116 3.676a1 1 0 011.768 0l1.943 3.682a1 1 0 00.714.519l4.102.71a1 1 0 01.547 1.682l-2.902 2.985a1 1 0 00-.273.84l.593 4.12a1 1 0 01-1.431 1.04l-3.736-1.837a1 1 0 00-.882 0l-3.736 1.837a1 1 0 01-1.431-1.04l.593-4.12a1 1 0 00-.273-.84L3.81 10.27a1 1 0 01.547-1.682l4.102-.71a1 1 0 00.714-.519l1.943-3.682z" class="filled"/></svg>
    </div> -->

    <div class="subactions" v-if="$route.name === $routeName.EMAIL_READ">
      <span class="time" v-if="date">{{ date }}</span>
      <div class="subactions-read">
        <div v-tooltip.right-end="'Reply'">
          <!-- prettier-ignore -->
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="$emit('reply')" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20.438 19.5a.562.562 0 01-.494-.293l-.016-.029a9.565 9.565 0 00-8.365-4.928H10.5v3.188a.563.563 0 01-.954.404l-6.375-6.188a.56.56 0 010-.807l6.375-6.188a.563.563 0 01.954.404v3.189c5.807.1 10.5 4.855 10.5 10.685a.562.562 0 01-.563.563z"/></svg>
        </div>
        <div v-tooltip.right-end="'Forward'">
          <!-- prettier-ignore -->
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="$emit('forward')" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.563 19.5c.202 0 .392-.11.493-.293l.016-.029a9.565 9.565 0 018.364-4.928H13.5v3.188a.563.563 0 00.954.404l6.375-6.188a.56.56 0 000-.807l-6.375-6.188a.563.563 0 00-.954.404v3.189C7.693 8.352 3 13.107 3 18.937a.562.562 0 00.563.563z"/></svg>
        </div>
        <!-- prettier-ignore -->
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="$router.push({ name: $routeName.EMAIL })" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.657 7.757a1 1 0 00-1.414-1.414L12.707 9.88a1 1 0 01-1.414 0L7.757 6.343a1 1 0 00-1.414 1.414l3.536 3.536a1 1 0 010 1.414l-3.536 3.536a1 1 0 101.414 1.414l3.536-3.536a1 1 0 011.414 0l3.536 3.536a1 1 0 101.414-1.414l-3.536-3.536a1 1 0 010-1.414l3.536-3.536z"/></svg>
      </div>
    </div>

    <div class="pagination-test" v-if="$route.name === $routeName.EMAIL">
      <span>{{ pageStart }}-{{ pageEnd }} of {{ listTotal }}</span>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="$emit('prev', {})" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.707 4.293a1 1 0 010 1.414L9.414 12l6.293 6.293a1 1 0 01-1.414 1.414l-7-7a1 1 0 010-1.414l7-7a1 1 0 011.414 0z"/></svg>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="$emit('next', {})" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.293 19.707a1 1 0 010-1.414L14.586 12 8.293 5.707a1 1 0 011.414-1.414l7 7a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0z"/></svg>
    </div>
  </div>
</template>

<script>
import PopperDropdown from "@component/Popper/PopperDropdown";
import FveCheckbox from "@fve/Switch/FveCheckbox";
import EmailMenuCheckbox from "@component/Email/EmailMenuCheckbox";
import EmailMenuMoveTo from "@component/Email/EmailMenuMoveTo";

export default {
  name: 'EmailNav',
  components: {
    PopperDropdown,
    FveCheckbox,
    EmailMenuCheckbox,
    EmailMenuMoveTo,
  },
  props: {
    date: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    isCheckIndeterminate: {
      type: Boolean,
      default: false,
    },
    view: {
      type: Object,
      required: true,
    },
    pageStart: { type: Number, default: 0, },
    pageEnd:   { type: Number, default: 0, },
    listTotal: { type: Number, default: 0, },
  },
  data() {
    return {
      isMarked: false,
      selectTypeList: [
        { title: 'All',    type: 'all' },
        { title: 'Read',   type: 'read' },
        { title: 'Unread', type: 'unread' },
        // TODO: Вернуть когда появится функционал на бэке
        // { title: "Starred", type: "starred" },
        // { title: "Unstarred", type: "unstarred" },
      ],
    };
  },
  computed: {
    moveToList() {
      const folderName = this.$route.query?.tag;
      const moveToList = [
        { title: "Spam", folder: this.$enum.EMAIL_FOLDER_NAME.SPAM },
        { title: "Trash", folder: this.$enum.EMAIL_FOLDER_NAME.TRASH },
      ];

      if (["Draft", "Spam"].includes(folderName)) {
        moveToList.unshift({
          title: "Inbox",
          folder: this.$enum.EMAIL_FOLDER_NAME.INBOX,
        });
      }
      return moveToList;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: var(--color-gray-04);
}

.email-action {
  padding: 0 32px;
  display: flex;

  .email-action__btn {
    display: flex;
    padding: 8px;
    &.delete > svg {
      fill: var(--color-red-03);
    }
    &.combined {
      padding-left: 0;
      padding-right: 0;
    }
    & > svg {
      cursor: pointer;
    }
    &.disabled {
      & > svg {
        cursor: not-allowed;
      }
    }
  }

  .email-action__caret {
    width: 16px;
    height: 16px;
  }

  .email-action__group {
    display: flex;
    position: relative;
    padding-right: 2px;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 20px;
      width: 1px;
      background-color: var(--color-gray-03);
    }
  }

  .email-action__dropdown {
    cursor: pointer;
    border-radius: 3px;
    &:hover {
      background-color: rgba(30, 30, 30, 0.08);
    }
  }
}

.email-action__dropdown-item {
  width: 220px;
  padding: 8px 16px;
}

.subactions {
  margin-left: auto;
  padding: 8px;
  display: flex;
  align-items: center;

  .subactions-read {
    display: flex;
    margin-left: 8px;

    & > * {
      cursor: pointer;
    }
    & > svg:not(:last-of-type) {
      margin-right: 4px;
    }
  }
}

.time {
  color: var(--color-gray-04);
  display: flex;
}

// TODO: Pagination test laout
.pagination-test {
  padding: 0 8px;
  color: var(--color-gray-06);
  font-size: 14px;
  line-height: 1.45;
  margin-left: auto;
  display: flex;
  align-items: center;

  & > span {
    margin-right: 12px;
  }

  & > svg {
    fill: var(--color-gray-05);
  }
}

.stroke-star {
  &:hover > .filled {
    fill: var(--color-gray-04-hover);
  }

  &.marked > .filled {
    fill: var(--color-secondary);
  }
}

.checkbox {
  width: 16px;
  height: 16px;
  border: 2px solid var(--color-secondary);
  margin: 4px;
  border-radius: 3px;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: var(--color-secondary);
    border-radius: 2px;
  }
}
</style>
